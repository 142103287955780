import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, BoxProps, styled } from '@mui/material';
import { DoubleThemeContext } from 'common-client';
import { Logo } from '../components/Logo';

const LogoHolderBox = styled(Box, { name: 'LogoHolderBox' })<BoxProps>(() => ({
  className: 'LogoHolderBox',
  my: '24px',
  width: '158px',
  height: '32px',
  '&:hover': {
    cursor: 'pointer',
  },
}));

export function LogoWrapper() {
  const history = useHistory();
  const { theme } = useContext(DoubleThemeContext);

  const handleHomeClick = () => {
    history.push('/');
  };

  return (
    <LogoHolderBox onClick={handleHomeClick}>
      <Logo
        isDarkModeEnabled={theme.palette.mode === 'dark'}
        height="100%"
        width="100%"
      />
    </LogoHolderBox>
  );
}
