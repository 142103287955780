import React, { SVGProps } from 'react';

export interface LogoProps extends SVGProps<SVGSVGElement> {
  isDarkModeEnabled: boolean;
}

export const Logo = ({ isDarkModeEnabled, ...props }: LogoProps) => {
  let palette;
  if (isDarkModeEnabled) {
    palette = ['#fff', '#e7e7e7', '#000'];
  } else {
    palette = ['#000', '#181818', '#fff'];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={158}
      height={32}
      fill="none"
      {...props}
    >
      <g fill={palette[1]} clipPath="url(#a)">
        <path
          d="M44 23.724h6.577c3.416 0 5.295-1.551 5.295-4.335 0-1.785-.833-2.996-2.392-3.506 1.431-.616 2.2-1.848 2.2-3.527C55.68 9.657 53.758 8 50.577 8H44v15.724Zm6.214-12.707c1.26 0 1.921.574 1.921 1.679 0 1.126-.683 1.742-1.921 1.742h-2.755v-3.42h2.755Zm.17 6.311c1.26 0 1.943.574 1.943 1.636 0 1.169-.661 1.742-1.943 1.742H47.46v-3.378h2.925ZM63.883 13.1v5.524c0 1.742-.576 2.465-1.986 2.465-1.345 0-1.943-.765-1.943-2.507V13.1h-3.288v6.672c0 2.613 1.495 4.228 3.929 4.228 1.28 0 2.605-.595 3.267-1.466l.213 1.19h3.096V13.1h-3.288ZM72.952 23.724v-7.905h2.028V13.1h-2.028V9.807l-3.289 1.348V13.1h-2.028v2.72h2.028v7.904h3.289Z"
        />
        <path
          d="M80.186 23.724v-7.905h2.028V13.1h-2.028V9.807l-3.288 1.348V13.1h-2.029v2.72h2.029v7.904h3.288ZM82.012 18.39c0 3.358 2.477 5.589 5.893 5.589 3.395 0 5.872-2.231 5.872-5.589 0-3.357-2.477-5.61-5.872-5.61-3.416 0-5.893 2.253-5.893 5.61Zm3.31-.02c0-1.594 1.046-2.657 2.583-2.657 1.516 0 2.562 1.063 2.562 2.656 0 1.615-1.046 2.678-2.562 2.678-1.537 0-2.584-1.063-2.584-2.678ZM97.97 23.724v-5.822c0-1.318.833-2.168 2.135-2.168 1.132 0 1.879.872 1.879 2.21v5.78h3.288v-6.566c0-2.784-1.473-4.398-4.014-4.398-1.388 0-2.605.53-3.267 1.444l-.213-1.104h-3.096v10.624h3.288ZM108.834 23.724h3.203l1.025-3.315c.683-2.231.875-3.06.961-3.506.064.446.341 1.7.875 3.442l1.025 3.379h3.054l3.736-10.624h-3.48l-1.025 3.463c-.107.383-.619 2.252-.747 3.017-.107-.68-.62-2.656-.726-3.017l-1.004-3.463h-3.374l-1.089 3.463c-.469 1.488-.576 1.891-.768 3.017a27.678 27.678 0 0 0-.662-3.017l-.983-3.463h-3.459l3.438 10.624ZM122.047 18.39c0 3.358 2.477 5.589 5.893 5.589 3.395 0 5.872-2.231 5.872-5.589 0-3.357-2.477-5.61-5.872-5.61-3.416 0-5.893 2.253-5.893 5.61Zm3.309-.02c0-1.594 1.047-2.657 2.584-2.657 1.516 0 2.562 1.063 2.562 2.656 0 1.615-1.046 2.678-2.562 2.678-1.537 0-2.584-1.063-2.584-2.678ZM134.141 18.39c0 3.358 2.477 5.589 5.893 5.589 3.395 0 5.872-2.231 5.872-5.589 0-3.357-2.477-5.61-5.872-5.61-3.416 0-5.893 2.253-5.893 5.61Zm3.309-.02c0-1.594 1.047-2.657 2.584-2.657 1.516 0 2.562 1.063 2.562 2.656 0 1.615-1.046 2.678-2.562 2.678-1.537 0-2.584-1.063-2.584-2.678ZM151.294 24c1.538 0 2.862-.659 3.438-1.636l.171 1.36h3.096V9.946l-3.288 1.208v2.923c-.619-.786-1.879-1.317-3.203-1.317-3.246 0-5.274 2.337-5.274 5.715 0 3.358 1.986 5.525 5.06 5.525Zm.79-2.996c-1.558 0-2.541-1.084-2.541-2.656s.983-2.656 2.541-2.656c1.538 0 2.605 1.062 2.605 2.656s-1.067 2.656-2.605 2.656Z"
        />
      </g>
      <g fill={palette[0]} clipPath="url(#b)">
        <path
          fillRule="evenodd"
          d="M2 16c0 7.732 6.268 14 14 14s14-6.268 14-14S23.732 2 16 2 2 8.268 2 16ZM16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0Z"
          clipRule="evenodd"
        />
        <path
          d="M4.533 12.615h8.364c-1.76 3.357-4.481 6.605-7.534 8.857a12.03 12.03 0 0 0 2.01 2.83c2.64-2.084 5.003-4.877 6.887-8.015v11.587a11.995 11.995 0 0 0 3.395.012v-11.6c1.933 3.166 4.348 5.863 7.008 7.978.801-.846 1.48-1.81 2.01-2.863-3.108-2.227-5.945-5.474-7.764-8.786h8.558a11.965 11.965 0 0 0-1.438-3.14h-8.374v-5.36a12.001 12.001 0 0 0-3.395.011v5.349H5.97c-.62.96-1.108 2.016-1.437 3.14Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={palette[2]} d="M44 8h114v16H44z" />
        </clipPath>
        <clipPath id="b">
          <path fill={palette[2]} d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
