import { Token } from '@uniswap/sdk-core';
import {
  addressEquals, Asset, Chain, createConfigs, NetworkConfig, SubgraphConfig,
} from 'common-client';
import ethereumLogoUrl from 'common-client/dist/assets/images/ethereum-logo.png';
import React, { ReactElement } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import BalanceIcon from '@mui/icons-material/Balance';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PublicIcon from '@mui/icons-material/Public';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ReactComponent as DiscordIcon } from 'assets/icons/DiscordIcon.svg';
import { createSvgIcon } from '@mui/material';
import { buttonwoodTokenList } from 'buttonwood-token-list';

export const SUPPORTED_CHAINS = [Chain.Mainnet, Chain.Goerli];

const SUBGRAPH_BASE_URI = 'https://api.thegraph.com/subgraphs/name';

// DO NOT EXPORT
function getToken(chainId: number, address: string): Token {
  const {
    name,
    symbol,
    decimals,
  } = buttonwoodTokenList.tokens.find(
    (token) => token.chainId === chainId && addressEquals(token.address, address),
  )!;
  return new Token(chainId, address, decimals, symbol, name);
}

export interface NetworkConfigExtension extends NetworkConfig {
  buttonswapRouterAddress: string;
}

export type ConfigExtension = Partial<Record<Chain, NetworkConfigExtension>>;

const config: ConfigExtension = {
  [Chain.Mainnet]: {
    network: {
      name: 'mainnet',
      chainId: 1,
      networkId: 1,
    },
    asset: {
      [Asset.USDT]: getToken(1, '0xdac17f958d2ee523a2206206994597c13d831ec7'),
      [Asset.AMPL]: getToken(1, '0xd46ba6d942050d489dbd938a2c909a5d5039a161'),
      [Asset.ETH]: getToken(1, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'),
      [Asset.WBTC]: getToken(1, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'),
      [Asset.WETH]: getToken(1, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'),
      [Asset.WAMPL]: getToken(1, '0xEDB171C18cE90B633DB442f2A6F72874093b49Ef'),
    },
    // TODO: update this
    buttonswapRouterAddress: '0x',
    chainInfo: {
      explorer: 'https://etherscan.io/',
      label: 'Ethereum',
      logoUrl: ethereumLogoUrl,
    },
  },
  [Chain.Goerli]: {
    network: {
      name: 'goerli',
      chainId: 5,
      networkId: 5,
    },
    asset: {
      [Asset.USDT]: getToken(5, '0xaFF4481D10270F50f203E0763e2597776068CBc5'),
      [Asset.AMPL]: getToken(5, '0x08c5b39F000705ebeC8427C1d64D6262392944EE'),
      [Asset.ETH]: getToken(5, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'),
      [Asset.WBTC]: getToken(5, '0xF19162950528A40a27d922f52413d26f71B25926'),
      [Asset.WETH]: getToken(5, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6'),
      [Asset.WAMPL]: getToken(5, '0x3b624861a14979537DE1B88F9565F41a7fc45FBf'),
    },
    buttonswapRouterAddress: '0xc9FeD4F970bd2b614d3cbAF21C9d37B9f856A544',
    chainInfo: {
      explorer: 'https://goerli.etherscan.io/',
      label: 'Goerli',
      logoUrl: ethereumLogoUrl,
    },
  },
};

// TODO: needs to be changed to get subgraph working
export const SUBGRAPH_CONFIG: SubgraphConfig = {
  [Chain.Mainnet.valueOf()]: {
    buttonswap: `${SUBGRAPH_BASE_URI}/fiddlekins/buttonswap-goerli`,
  },
  [Chain.Goerli.valueOf()]: {
    buttonswap: `${SUBGRAPH_BASE_URI}/fiddlekins/buttonswap-goerli`,
  },
};

interface MenuLines {
  icon: ReactElement;
  name: string;
  url: string;
  node?: ReactElement;
}

const GeneratedDiscordIcon = createSvgIcon(<DiscordIcon />, 'DiscordIcon');

export const menuConfig: Array<MenuLines> = [
  {
    icon: <PublicIcon />,
    name: 'Zero',
    url: 'https://app.zero.button.finance/',
  },
  {
    icon: <PublicIcon />,
    name: 'Tranche',
    url: 'https://app.tranche.button.finance/',
  },
  {
    icon: <PublicIcon />,
    name: 'Auctions',
    url: 'https://app.auctions.button.finance/',
  },
  {
    icon: <PublicIcon />,
    name: 'Wrap',
    url: 'https://app.wrap.button.finance/',
  },
  {
    icon: <TwitterIcon />,
    name: 'Twitter',
    url: 'https://twitter.com/ButtonDeFi',
  },
  {
    icon: <GeneratedDiscordIcon />,
    name: 'Discord',
    url: 'https://discord.gg/P8gFKUYtfG',
  },
  {
    icon: <PublicIcon />,
    name: 'Foundation',
    url: 'https://button.foundation/',
  },
  {
    icon: <ArticleIcon />,
    name: 'Docs',
    url: 'https://docs.prl.one',
  },
  // TODO: change to correct dune analytics page
  {
    icon: <InsertChartIcon />,
    name: 'Analytics',
    url: 'https://dune.xyz/prl/<placeholder-client>',
  },
  {
    icon: <HelpCenterIcon />,
    name: 'About',
    url: 'https://auction.button.finance',
  },
  {
    icon: <BalanceIcon />,
    name: 'Terms',
    url: 'https://auction.button.finance/terms/',
  },
];

export function getConfig(chain: Chain): NetworkConfigExtension | null {
  if (chain === Chain.Unsupported) {
    return null;
  }
  return config[chain] || null;
}

const { ConfigContext, ConfigProvider } = createConfigs(getConfig);
export { ConfigContext, ConfigProvider };
