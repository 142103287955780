import {
  Box, BoxProps, CircularProgress, styled,
} from '@mui/material';
import { providers } from 'ethers';
import { withProfiler } from '@sentry/react';
import {
  BackgroundButtonwood,
  ToastProvider,
  TokenListProvider,
  TokenUSDPriceProvider,
  WalletConnectionProvider,
  Web3Provider,
  WrapperMapProvider,
} from 'common-client';
import { ConfigProvider, SUPPORTED_CHAINS } from 'config';
import React, { lazy, Suspense, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { SubgraphProvider } from './contexts/SubgraphContext';
import { TopBarWrapper } from './wrappers/TopBarWrapper';

declare global {
  interface Window {
    ethereum: providers.ExternalProvider;
  }
}

window.ethereum = window.ethereum || {};

const AppDiv = styled(Box, { name: 'AppDiv' })<BoxProps>(() => ({
  minHeight: '100vh',
  WebkitTapHighlightColor: 'transparent',
}));

const BodyBox = styled(Box, { name: 'BodyBox' })<BoxProps>(() => ({}));

const DynamicLoader = ({ component }: { component: string }) => {
  const LazyComponent = useMemo(() => lazy(() => import(`routes/${component}`)), [component]);
  return (
    <Suspense fallback={<CircularProgress />}>
      <LazyComponent />
    </Suspense>
  );
};

const queryClient = new QueryClient();

function App() {
  return (
    <IntlProvider locale={navigator.language}>
      <Router>
        <Web3Provider>
          <WalletConnectionProvider supportedChains={SUPPORTED_CHAINS}>
            <QueryClientProvider client={queryClient}>
              <TokenListProvider
                defaultBlockList={[
                  'wrapper_tranche',
                ]}
              >
                <TokenUSDPriceProvider>
                  <WrapperMapProvider>
                    <ConfigProvider>
                      <SubgraphProvider>
                        <ToastProvider>
                          <AppDiv>
                            <BackgroundButtonwood />
                            <TopBarWrapper />
                            <BodyBox
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              flex={1}
                              pt="104px"
                              pb="96px"
                            >
                              <Switch>
                                <Route path="/swap">
                                  <DynamicLoader component="SwapRoute" />
                                </Route>
                                <Route path="/pools">
                                  <DynamicLoader component="PoolsRoute" />
                                </Route>
                                <Route path="/analytics">
                                  <DynamicLoader component="AnalyticsRoute" />
                                </Route>
                                <Route path="/">
                                  <Redirect to="/swap" />
                                </Route>
                              </Switch>
                            </BodyBox>
                          </AppDiv>
                        </ToastProvider>
                      </SubgraphProvider>
                    </ConfigProvider>
                  </WrapperMapProvider>
                </TokenUSDPriceProvider>
              </TokenListProvider>
            </QueryClientProvider>
          </WalletConnectionProvider>
        </Web3Provider>
      </Router>
    </IntlProvider>
  );
}

export default withProfiler(App);
