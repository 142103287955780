import React, { useContext, useMemo } from 'react';
import {
  Chain, ConnectWalletButtonV2Wrapper, DoubleThemeContext, NetworkButtonWrapper, TopBarV3,
} from 'common-client';
import { useHistory } from 'react-router-dom';
import { MenuLinkConfig } from 'common-client/dist/components/RouteButtonGroup/StandardLink';
import { menuConfig } from '../config';
import { LogoWrapper } from './LogoWrapper';

const menuItems = menuConfig.map(({
  icon, name, url, node,
}) => ({
  label: name,
  path: url,
  image: icon,
  node,
}));

export function TopBarWrapper() {
  const history = useHistory();
  const { theme, toggleTheme } = useContext(DoubleThemeContext);

  const routeList: MenuLinkConfig[] = useMemo(() => [
    {
      href: '/swap',
      label: 'Swap',
      onClick: (e) => {
        e.preventDefault();
        history.push('/swap');
      },
    },
    {
      href: '/pools',
      label: 'Pools',
      onClick: (e) => {
        e.preventDefault();
        history.push('/pools');
      },
    },
    {
      href: '/analytics',
      label: 'Analytics',
      onClick: (e) => {
        e.preventDefault();
        history.push('/analytics');
      },
    },
  ], [history]);

  return (
    <>
      <TopBarV3
        logo={<LogoWrapper />}
        menuItems={menuItems}
        routeList={routeList}
        connectWalletButton={<ConnectWalletButtonV2Wrapper />}
        networkButton={
          <NetworkButtonWrapper availableChains={[Chain.Mainnet]} testChains={[Chain.Goerli]} />
        }
        onThemeSwitch={toggleTheme}
        isDarkModeEnabled={theme.palette.mode === 'dark'}
      />
    </>
  );
}
